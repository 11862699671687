import React, { useState, useEffect} from 'react';
import { HashLink as NavLink } from 'react-router-hash-link';

//SVG imports
import Logo from './../../../assets/FooterLogo.svg';
import OpenMenuIcon from './../../../assets/menu-icons/open-menu-icon.svg';
import CloseMenuIcon from './../../../assets/menu-icons/close-menu-icon.svg';

const MobileMenu = () => {
    const [menuOpenBool, setMenuOpen] = useState(false);

    const handleMenuIcon = () => {
        const menuElement = document.getElementById("mobile-menu_wrapper")
        if(menuOpenBool === false){
            setMenuOpen(true);
            menuElement.classList.add("menu-open");
        } else {
            setMenuOpen(false)
            menuElement.classList.remove("menu-open");
        };
    }

    const handleCloseMenu = () => {
        setMenuOpen(false);
    }

    return (
        <div id='mobile-menu_wrapper' className='mobile-menu_wrapper'> 
            {menuOpenBool ? <img className="close-menu-icon" src={CloseMenuIcon} onClick={handleMenuIcon}/> : <img className="open-menu-icon" src={OpenMenuIcon} onClick={handleMenuIcon}/>}
            {menuOpenBool &&
                <div className='mobile-menu-list_wrapper'>
                    <img className='mobile-menu-logo' src={Logo}/>
                    <div className='mobile-menu-list'>
                        <NavLink to="#about-section" className='mobile-menu-option' onClick={handleMenuIcon}>OM OSS</NavLink>
                        <NavLink to="#information-section" className='mobile-menu-option' onClick={handleMenuIcon}>INFORMATION</NavLink>
                        <NavLink to="#contact-section" className='mobile-menu-option' onClick={handleMenuIcon}>KONTAKTA OSS</NavLink>
                        <NavLink to="#gdpr-section" className='mobile-menu-option' onClick={handleMenuIcon}>GDPR</NavLink>
                    </div>
                </div>
            }
        </div>
    )
}

export default MobileMenu;