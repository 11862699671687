import React from 'react';
import HammurabiImage from '../../assets/photos/Hammurabi.png';
import KatarinaImage from '../../assets/photos/Katarina.png';

const About = () => {

    return (
        <div id="about-section" className='about_wrapper'>
            <div className='about_clinic'>
                <h1>GRÅ STARRKIRURGI - VÅR SPECIALITET</h1>
                <p className='center'>
                    Vår ögonklinik i Lund utför enbart grå starroperationer: vi har modern utrustning, gedigen
                    erfarenhet samt hög tillgänglighet med korta väntetider. Vi sätter patienterna i centrum och
                    vårt främsta mål är att du som patient ska bli nöjd. Vi har ett mycket nära samarbete med
                    Ögoncentrum Annedal och har därför möjlighet att hjälpa patienter även med andra
                    ögonsjukdomar.
                </p>
                <p className='center'>
                    Vi utför vårt arbete i samarbete med Region Skåne som en del av vårdvalet för grå starr. Vi
                    tillämpar samma avgifter som Region Skåne för offentlig vård, frikort och högkostnadskort
                    gäller. Gratis sjukvård för barn och ungdomar under 20 år samt för alla över 85 år.
                </p>
            </div>
            
            <div className='about_staff'>
                <div className='staff_section'>
                <img className='staff_image' src={HammurabiImage} />
                    <div className='staff_info'>
                        <h2 className='staff_title'>Ögonläkare Hammurabi Bartuma</h2>
                        <p className='staff_text'>
                            Hammurabi Bartuma är specialist i ögonsjukdomar sedan 2017, samma år som han också tog den europeiska specialistexamen. 
                            Hammurabi började sin ögonkarriär på S:t Eriks Ögonsjukhus i Stockholm och arbetade där som näthinnekirurg och ögononkolog. 
                            Därefter flyttade Hammurabi åter till Skåne och har arbetat som kataraktkirurg vid Kristianstads ögonmottagning och slutligen även som katarakt- och glaukomkirurg på Skånes Universitetssjukhus i Malmö och Lund. 
                            Hammurabi disputerade 2011 på Lunds universitet och gjorde en post doc på Karolinska Institutet och S:t Eriks Ögonsjukhus om stamcellsterapi av åldersförändringar i gula fläcken. 
                        </p>
                    </div>
                </div>
                
                <div className='staff_section'>
                <img className='staff_image' src={KatarinaImage}/>
                    <div className='staff_info'>
                        <h2 className='staff_title'>Ögonläkare Katarina Bartuma</h2>
                        <p className='staff_text'>
                            Katarina Bartuma är specialist i ögonsjukdomar sedan 2017, samma år som hon också tog den europeiska specialistexamen. 
                            Katarina började sin ögonkarriär på S:t Eriks Ögonsjukhus i Stockholm och arbetade där som ögononkolog. 
                            Därefter flyttade Katarina åter till Skåne och arbetar på Ögoncentrum Annedal där hon utför ögonlockskirurgi och arbetar även som kataraktkirurg vid Kristianstads ögonmottagning. 
                            Katarina disputerade 2011 på Lunds universitet och gjorde en post doc på Karolinska Institutet och S:t Eriks Ögonsjukhus inom ögononkologi.
                        </p>
                    </div>
                </div>
                
            </div>
            <p className='center'>
                Både Katarina och Hammurabi Bartuma är efterfrågade föreläsare inom områden som stamcellsterapi, ögononkologi och kataraktkirurgi. 
                Oktober 2024 startade Katarina och Hammurabi Bartuma kliniken ALB GRÅSTARRKIRURGI. 
            </p>
        </div>
    )
}

export default About;